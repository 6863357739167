import Layout from "@/components/App/Layout";
import SEO from "@/components/SEO";
import BreadCrumb from "@/components/Shared/extra/BreadCrumb";
import { StructuredDataSnippetTag } from "@/components/Shared/StructuredDataTag";
import { SEOType, ServiceCardOptions } from "@/types";
import { graphql, PageProps } from "gatsby";
import React from "react";
import RelatedServices from "./RelatedServices";
import ServiceDetailView from "./ServiceDetailView";

export const query = graphql`
  query ($slug: String!) {
    contentfulServiceModelRef(slug: { eq: $slug }) {
      detailedPage {
        heading
        seo {
          metaTitle
          metaDescription
          metaUrl
          metaAuthor
          metaKeywords
          metaImage {
            file {
              url
            }
          }
        }

        structuredDataSnippets {
          snippet {
            id
            snippet
          }
        }
        content {
          raw
          references {
            ... on ContentfulSectionTitleLeftImageAndRightPointsRich {
              contentful_id
              __typename
              section
              image {
                file {
                  url
                }
              }
              points {
                points
              }
            }

            ... on ContentfulSectionTitleRightImageAndLeftPoints {
              contentful_id
              __typename
              section
              image {
                file {
                  url
                }
              }
              points {
                points
              }
            }

            ... on ContentfulApplicationAreasServiceDetailEditorRef {
              contentful_id
              __typename
              applicationsAreasList {
                id
                icon {
                  file {
                    url
                  }
                }
                applicationAreaName
              }
            }

            ... on ContentfulTitleWithBulletPointBelowRichTextEditorRef {
              contentful_id
              __typename
              title
              bulletPoints
            }

            ... on ContentfulFrequentlyAskedQuestionListForEmbeddedView {
              contentful_id
              __typename
              list {
                question
                id
                answer {
                  answer
                }
              }
            }
          }
        }

        relatedServiceText

        relatedServices {
          id
          icon {
            file {
              url
            }
          }
          slug
          title
          description {
            description
          }
        }

        footerContent {
          raw
        }
      }
    }
  }
`;

interface ServiceDetailTemplateProps extends PageProps {
  data: {
    contentfulServiceModelRef: {
      detailedPage: {
        heading: string;
        seo: SEOType;
        structuredDataSnippets?: any;
        content: {
          raw: any;
          references: any[];
        };
        relatedServiceText: string | null;
        relatedServices: ServiceCardOptions[] | null;
        footerContent?: any;
      };
    };
  };
}

const ServiceDetailTemplate: React.FC<ServiceDetailTemplateProps> = ({
  data: { contentfulServiceModelRef },
}) => {
  return (
    <Layout footerContent={contentfulServiceModelRef?.detailedPage?.footerContent}>
      <SEO contentfulSeo={contentfulServiceModelRef.detailedPage.seo} />
      <StructuredDataSnippetTag
        snippets={contentfulServiceModelRef.detailedPage.structuredDataSnippets}
      />
      <BreadCrumb
        pageTitle={contentfulServiceModelRef.detailedPage.heading}
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/services/", pathName: "Services" },
          {
            path: "/",
            pathName: contentfulServiceModelRef.detailedPage.heading,
          },
        ]}
      />
      <br />
      <ServiceDetailView content={contentfulServiceModelRef?.detailedPage?.content} />
      {contentfulServiceModelRef.detailedPage.relatedServices?.length ? (
        <RelatedServices
          services={contentfulServiceModelRef.detailedPage.relatedServices}
          heading={contentfulServiceModelRef.detailedPage.relatedServiceText}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default ServiceDetailTemplate;
