import { Link } from "gatsby";
import React, { FC } from "react";

const BreadCrumb: FC<{ pageTitle: string; paths: { path: string; pathName: string }[] }> = ({
  pageTitle,
  paths = [],
}) => {
  return (
    <div className="page-title-area">
      <div className="container">
        <div className="page-title-content">
          <h1>{pageTitle}</h1>
          <ul>
            {paths.map(({ path, pathName }, i) => {
              if (i === paths.length - 1) {
                return <li key={pathName}> {pathName}</li>;
              } else {
                return (
                  <li key={pathName}>
                    <Link to={path}>{pathName}</Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>

      <div className="shape-img1">
        <img src="/images/shape/shape1.svg" alt="banner" />
      </div>
      <div className="shape-img2">
        <img src="/images/shape/shape2.png" alt="banner" />
      </div>
      <div className="shape-img3">
        <img src="/images/shape/shape3.png" alt="banner" />
      </div>
    </div>
  );
};

export default BreadCrumb;
