import ServiceCardUI from "@/components/Shared/Design/ServiceCardUi";
import { ServiceCardOptions } from "@/types";
import React, { FC } from "react";

const RelatedServices: FC<{ services: ServiceCardOptions[]; heading: string }> = ({
  services,
  heading,
}) => {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>{heading || "More Services You Might Like"}</h2>
        </div>

        <div className="row">
          {services.map(
            ({
              id,
              icon: {
                file: { url },
              },
              title,
              description: { description },
              slug,
            }) => {
              if (typeof window !== "undefined") {
                if (slug === window?.location.href?.split("/services/")[1]) {
                  return null;
                }
              }
              return (
                <ServiceCardUI
                  key={id}
                  description={description}
                  icon={url}
                  slug={"/" + slug}
                  title={title}
                />
              );
            },
          )}
        </div>
      </div>
    </section>
  );
};

export default RelatedServices;
