import { graphql, Link, StaticQuery } from "gatsby";
import React, { FC } from "react";
import ContactInfoWidget from "../../components/Shared/extra/ContactInfoWidget";

const ServiceSidebar: FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulServicesPage {
            services {
              slug
              title
              id
            }
          }
        }
      `}
      render={({ contentfulServicesPage: { services } }) => {
        return (
          <div className="services-details-info">
            <ul className="services-list">
              {services?.map(({ id, title, slug }) => {
                return (
                  <li key={id}>
                    <Link to={"/" + slug} activeClassName="active">
                      {title}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div className="download-file">
              <h3>Brochures</h3>

              <ul>
                <li>
                  <a
                    href={"/docs/ZartekPortfolio.pdf"}
                    download
                    id="brochure_download_portfolio_link"
                  >
                    Zartek Portfolio <i className="bx bxs-file-pdf"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={"/docs/ZartekBrandingPortfolio.pdf"}
                    download
                    id="brochure_download_branding_portfolio_link"
                  >
                    Branding Portfolio <i className="bx bxs-file-pdf"></i>
                  </a>
                </li>
              </ul>
            </div>
            <ContactInfoWidget />
          </div>
        );
      }}
    />
  );
};

export default ServiceSidebar;
