import React from "react";

const ContactInfoWidget: React.FC = () => {
  return (
    <div className="services-contact-info">
      <h3>Contact Info</h3>

      <ul>
        <li>
          <div className="icon">
            <i className="bx bx-user-pin"></i>
          </div>
          <span>Mobile:</span>
          <a id="service_contact_link" href="tel:+91 8069647545">
            +91 8069647545
          </a>
        </li>
        <li>
          <div className="icon">
            <i className="bx bx-map"></i>
          </div>
          <span>Location:</span>
          180 Northfield Dr <br />
          Waterloo, ON N2L 0C7, Canada
        </li>
        <li>
          <div className="icon">
            <i className="bx bx-envelope"></i>
          </div>
          <span>Email:</span>
          <a id="service_email_link" href="mailto:info@zartek.ca">
            info@zartek.ca
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ContactInfoWidget;
